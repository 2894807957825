@use '../utils/' as *;

.student-list {
  .ant-table {
    .ant-table-tbody {
      .ant-image {
        width: 50px;
        .profile-photo {
          width: 50px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .ant-btn {
        padding: 2px 7px;
      }
    }
  }
}

.add-student {
  height: 100%;
  .data-form {
    height: 100%;
  }
}
.data-form {
  max-height: calc(100vh - 148px);
  height: calc(100% - 70px);
  .full-height {
    height: 100%;
  }
}
