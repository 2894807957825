@use 'variable' as *;
/*text color css start here*/
.text-red1 {
  color: $red1;
}
.text-red2 {
  color: $red2;
}
.text-red3 {
  color: $red3;
}
.text-green1 {
  color: $green1;
}
.text-green2 {
  color: $green2;
}
.text-green3 {
  color: $green3;
}
.text-blue1 {
  color: $blue1;
}
.text-blue2 {
  color: $blue2;
}
.text-blue3 {
  color: $blue3;
}
.text-yellow1 {
  color: $yellow1;
}
.text-yellow2 {
  color: $yellow2;
}
.text-yellow3 {
  color: $yellow3;
}
.text-primary {
  color: $primary;
}
.text-primaryTint1 {
  color: $primaryTint1;
}
.text-primaryTint2 {
  color: $primaryTint2;
}
.text-primaryShade1 {
  color: $primaryShade1;
}
.text-primaryShade2 {
  color: $primaryShade2;
}
.text-neturals {
  color: $neturals;
}
.text-neturals1 {
  color: $neturals1;
}
.text-neturals2 {
  color: $neturals2;
}
.text-neturals3 {
  color: $neturals3;
}
.text-neturals4 {
  color: $neturals4;
}
.text-neturals5 {
  color: $neturals5;
}
.text-neturals6 {
  color: $neturals6;
}
.text-neturals7 {
  color: $neturals7;
}
.text-neturals8 {
  color: $neturals8;
}
.text-neturals9 {
  color: $neturals9;
}
.text-background1 {
  color: $background1;
}
.text-background2 {
  color: $background2;
}
.text-stroke-light {
  color: $strokeLight;
}
.text-stroke-dark {
  color: $strokeDark;
}
/*text color css end here*/
/*background color css start here*/
.bg-red1 {
  background-color: $red1;
}
.bg-red2 {
  background-color: $red2;
}
.bg-red3 {
  background-color: $red3;
}
.bg-green1 {
  background-color: $green1;
}
.bg-green2 {
  background-color: $green2;
}
.bg-green3 {
  background-color: $green3;
}
.bg-blue1 {
  background-color: $blue1;
}
.bg-blue2 {
  background-color: $blue2;
}
.bg-blue3 {
  background-color: $blue3;
}
.bg-yellow1 {
  background-color: $yellow1;
}
.bg-yellow2 {
  background-color: $yellow2;
}
.bg-yellow3 {
  background-color: $yellow3;
}
.bg-primary {
  background-color: $primary;
}
.bg-primaryTint1 {
  background-color: $primaryTint1;
}
.bg-primaryTint2 {
  background-color: $primaryTint2;
}
.bg-primaryShade1 {
  background-color: $primaryShade1;
}
.bg-primaryShade2 {
  background-color: $primaryShade2;
}
.bg-neturals {
  background-color: $neturals;
}
.bg-neturals1 {
  background-color: $neturals1;
}
.bg-neturals2 {
  background-color: $neturals2;
}
.bg-neturals3 {
  background-color: $neturals3;
}
.bg-neturals4 {
  background-color: $neturals4;
}
.bg-neturals5 {
  background-color: $neturals5;
}
.bg-neturals6 {
  background-color: $neturals6;
}
.bg-neturals7 {
  background-color: $neturals7;
}
.bg-neturals8 {
  background-color: $neturals8;
}
.bg-neturals9 {
  background-color: $neturals9;
}
.bg-background1 {
  background-color: $background1;
}
.bg-background2 {
  background-color: $background2;
}
.bg-stroke-light {
  background-color: $strokeLight;
}
.bg-stroke-dark {
  background-color: $strokeDark;
}
/*background color css end here*/
