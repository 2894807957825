@use '../utils/' as *;
.home-page-wrapper {
  width: 100%;
  background: $neturals9;
  color: $neturals;
  min-height: max(100vh - 96px - 239px, 500px);
  position: relative;
  display: table;
  @include respond-to($screen767) {
    padding: 200px 0;
  }
  .home-page-wrapper-block {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    .container {
      position: relative;
      z-index: 1;
      h1 {
        margin-bottom: 10px;
        @include respond-to($screen767) {
          margin-bottom: 6px;
        }
      }
      .search-wrapper-home {
        max-width: 500px;
        width: 100%;
        margin: 32px auto 0 auto;
        text-align: left;
        .ant-select {
          width: 100%;
          height: 64px;
          @include respond-to($screen767) {
            height: 48px;
          }
          .ant-select-arrow {
            inset-inline-end: 16px;
            @include respond-to($screen767) {
              inset-inline-end: 8px;
            }
          }
          .ant-select-selector {
            padding: 0 16px;
            @include respond-to($screen767) {
              padding: 0 8px;
            }
            .ant-select-selection-search {
              inset-inline-start: 16px;
              inset-inline-end: 16px;
            }
            input,
            .ant-select-selection-placeholder {
              @include headingFive;
              padding: 0;
            }
          }
          .ant-input,
          .ant-input-group-addon,
          .ant-input-group-addon button {
            border-color: transparent;
            background: transparent;
          }
          .ant-btn-icon {
            vertical-align: middle;
          }
          .ant-select-arrow {
            svg {
              @include respond-to($screen767) {
                width: 24px;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
  .home-page-background {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 346px;
    @include respond-to($screen767) {
      height: 127px;
    }
    img {
      object-fit: cover;
      object-position: top;
    }
  }
}

.notification-card {
  padding: 24px;
  border-radius: 8px;
  background-color: white;
  // border-right: 1px solid;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  .header {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 4px;
    .notification-title {
      font-size: 16px;
      font-weight: bold;
    }
    .post-time {
      font-weight: bold;
    }
  }
}

.font-600 {
  font-weight: 600;
}
