@use '../utils' as *;
.ant-btn {
  border-width: 1.5px;
  @include respond-to($screen767) {
    font-size: 14px;
  }
  &.ant-btn-sm {
    @include respond-to($screen767) {
      height: 28px;
      font-size: 12px;
      padding: 4px 12px;
    }
  }
  &.ant-btn-lg {
    @include respond-to($screen767) {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
