@use '../utils/mixin' as *;
/*typograpy css start here*/
h1,
.h1 {
  @include headingOne;
  &.medium {
    font-weight: 500;
  }
}
h2,
.h2 {
  @include headingTwo;
  &.medium {
    font-weight: 500;
  }
}
h3,
.h3 {
  @include headingThree;
  &.medium {
    font-weight: 500;
  }
}
h4,
.h4 {
  @include headingFour;
}
h5,
.h5 {
  @include headingFive;
  &.medium {
    font-weight: 500;
  }
}
h6,
.h6 {
  @include headingSix;
  &.medium {
    font-weight: 500;
  }
}
.body-xl {
  @include bodyXl;
}
.body-l {
  @include bodyL;
}
.body-m {
  @include bodyM;
}
.body-base {
  @include bodyBase;
}
.body-meta {
  @include bodyMeta;
}
.body-micro {
  @include bodyMicro;
}
/*typography css end here*/
