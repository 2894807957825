@use '../utils/' as *;
.ant-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  &.ant-avatar-lg {
    line-height: 40px;
    @include respond-to($screen767) {
      height: 24px;
      width: 24px;
      font-size: 12px;
      line-height: 22px;
    }
  }
}
