@use '../utils/' as *;
@forward './typography';

.page-bg {
  background-color: $white-color;
  // padding: 15px;
  border-radius: 5px;
  // height: 100%;
}

/*text alignment start here*/
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/*text alignment end here*/
.uppercase {
  text-transform: uppercase;
}

.display-block {
  display: block;
}

.display-table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.vertical-center {
  vertical-align: middle;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.m-hide {
  display: block;

  @include respond-to($screen767) {
    display: none;
  }
}

.d-hide {
  display: none;

  @include respond-to($screen767) {
    display: block;
  }
}

.inline-block {
  display: inline-block;
}

.error-page {
  padding: 48px 0;
  text-align: center;
  h1 {
    font-size: 66px;
    line-height: 1.4;
    font-weight: 500;
  }
  h3 {
    opacity: 0.7;
  }
  p {
    opacity: 0.6;
    max-width: 680px;
    margin: 0 auto 40px auto;
  }
  .error-divider {
    max-width: 200px;
    margin: 36px auto;
    &::after {
      content: '';
      height: 4px;
      width: 100%;
      background: $primary;
      border-radius: 4px;
      position: relative;
      display: block;
    }
  }
}
.full-page {
  height: calc(100vh - 92px);
}
.display-tbl {
  height: 100%;
  display: table;
  width: 100%;
  padding: 42px 0;
}
.tbl-cell {
  display: table-cell;
  vertical-align: middle;
}
.loader-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    width: 50px;
    height: 50px;
    border: 5px solid $neturals2;
    border-bottom-color: $primary;
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.anchor-button {
  background: $red3;
  color: $neturals;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  font-size: 18px;
  transition: all ease 0.3s;
  &:hover {
    color: $neturals;
    opacity: 0.8;
  }
}

.count-icon {
  @include bodyM;
  @include respond-to($screen767) {
    @include bodyBase;
  }
}

.menu-item-watchlist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  .text-title {
    @include bodyM;
    color: $neturals6;
    @include respond-to($screen767) {
      @include bodyBase;
    }
    &.active {
      color: $neturals9;
    }
    &.disabled {
      color: $neturals3;
    }
  }
  .text-count {
    @include bodyBase;
    color: $neturals5;
    @include respond-to($screen767) {
      @include bodyMeta;
    }
    &.disabled {
      color: $neturals5;
    }
  }
  .ant-radio-wrapper {
    &.disabled {
      opacity: 0.5;
    }
  }
}
.no-data-table {
  min-height: 284px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include bodyBase;
  color: $neturals7;
}

.pointer-none {
  pointer-events: none;
  cursor: none;
}

.text-header {
  .text-sub-header {
    font-size: 16px;
    margin-left: 8px;
  }
}
