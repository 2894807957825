@use '../utils/' as *;
.why-us-page {
  padding-block: 48px;
  .ant-row {
    align-items: center;
  }
  .why-us-grid {
    display: flex;
    gap: 32px;
    align-items: center;
    @include respond-to($screen767) {
      flex-direction: column-reverse;
    }
    .why-us-content {
      flex: 1;
      width: 50%;
      @include respond-to($screen767) {
        width: 100%;
      }
      h1 {
        margin-bottom: 8px;
      }
      ul {
        margin-top: 24px;
        li {
          display: flex;
          align-items: center;
          gap: 8px;
          @include bodyM;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
    .why-us-img {
      flex: 1;
      width: 50%;
      text-align: center;
      @include respond-to($screen767) {
        width: 100%;
      }
      svg {
        max-width: 500px;
        width: 100%;
        height: auto;
      }
    }
  }
}
