:root {
  --red1: #ffe5e5;
  --red2: #ff3b3b;
  --red3: #c30000;
  --green1: #e1fef1;
  --green2: #05a861;
  --blue1: #e6f0ff;
  --green3: #03663b;
  --blue2: #0063f7;
  --blue3: #003380;
  --yellow1: #fff8dd;
  --yellow2: #ffcc00;
  --yellow3: #997a00;
  --primaryTint1: #fff3f3;
  --primaryTint2: #ff5a5a;
  --primary: #c00000;
  --primaryShade1: #8d0000;
  --primaryShade2: #5a0000;
  --neturals: #ffffff;
  --neturals1: #f2f2f2;
  --neturals2: #e6e6e6;
  --neturals3: #d9d9d9;
  --neturals4: #cccccc;
  --neturals5: #bfbfbf;
  --neturals6: #8c8c8c;
  --neturals7: #4c4c4c;
  --neturals8: #262626;
  --neturals9: #000000;
  --strokeLight: #ebebf0;
  --strokeDark: #ceceda;
  --background1: #fafafc;
  --background2: #f2f4f7;
  --transparent: transparent;
  --primary-font: 'jost', sans-serif;
}
/* color start here*/
$red1: var(--red1);
$red2: var(--red2);
$red3: var(--red3);
$green1: var(--green1);
$green2: var(--green2);
$green3: var(--green3);
$blue1: var(--blue1);
$blue2: var(--blue2);
$blue3: var(--blue3);
$yellow1: var(--yellow1);
$yellow2: var(--yellow2);
$yellow3: var(--yellow3);
$primaryTint1: var(--primaryTint1);
$primaryTint2: var(--primaryTint2);
$primary: var(--primary);
$primaryShade1: var(--primaryShade1);
$primaryShade2: var(--primaryShade2);
$neturals: var(--neturals);
$neturals1: var(--neturals1);
$neturals2: var(--neturals2);
$neturals3: var(--neturals3);
$neturals4: var(--neturals4);
$neturals5: var(--neturals5);
$neturals6: var(--neturals6);
$neturals7: var(--neturals7);
$neturals8: var(--neturals8);
$neturals9: var(--neturals9);
$strokeLight: var(--strokeLight);
$strokeDark: var(--strokeDark);
$background1: var(--background1);
$background2: var(--background2);
$transparent: transparent;
$container: 1216px;

// ColorPicker Variables
$white-color: #ffffff;
$black-color: #000000;

//global style variables
$colorPrimary: #038fde;
$secondary-color: #fa8c15;
$text-color: #545454;
$text-color-secondary: #595959;
$heading-color: #535353;
$header-text-color: #262626;
$layout-header-background: #fefefe;
$layout-header-background-dark: #003365;
// $layout-footer-background: #fffffe;
$body-background: #f5f5f5;
$nav-bar-bg: #fffffc;
$nav-bar-text-color: $text-color;
$blue: #85ceff;
$nav-dark-bg: #003366;
$nav-dark-text-color: #038fdd;
$menu-dark-bg: $nav-dark-bg;
$menu-dark-color: $nav-dark-text-color;
$menu-dark-highlight-color: $secondary-color;
$menu-dark-arrow-color: $nav-dark-text-color;
$hor-nav-text-color: #fffffd;

$border-radius-base: 4px;
$border-radius-sm: 4px;
$border-radius-lg: 10px;
$layout-header-background: #fff;
$layout-header-padding: 0 32px;
$card-actions-li-margin: 12px 24px;

// $font-size-base: 14px;
// $font-size-lg: $font-size-base + 0.1em;
// $font-size-sm: 12px;

// font-size: clamp(min, value, max);

$heading-1-size: clamp(25px, 4.6vw, 38px);
$heading-2-size: clamp(20px, 4vw, 30px);
$heading-3-size: clamp(18px, 3.8vw, 24px);
$heading-4-size: clamp(16px, 3vw, 20px);
$heading-5-size: clamp(14px, 2.5vw, 16px);

/*typography css end here*/
//*all variable list link are here*//
/**https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less**/
