/* Box sizing rules */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: initial;
  outline: none;
  -webkit-tap-highlight-color: transparent !important;
  font-family: var(--primary-font);

  // to remove tap styles for smaller devices
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

html,
body,
div,
span,
section,
p,
a,
img,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
select,
input,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd option {
  padding: 0;
  margin: 0;
  border: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ol,
ul {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: auto;
  line-height: 1.6;
  background-color: var(--color-white);
  scroll-behavior: smooth;
}

/* A elements that don't have a class get default styles */
a {
  text-decoration: none;
  display: inline-block;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'],
.pointer {
  cursor: pointer;
}
button {
  border: none;
  background-color: transparent;
}
/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
.rounded-full {
  border-radius: 50%;
}
