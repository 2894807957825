@use '../utils/' as *;

.ant-form {
  .ant-form-item {
    .ant-form-item-margin-offset {
      margin: 0 !important;
      // to overwrite ant class
    }
    .ant-form-item-label label {
      font-size: 16px;
      &::before {
        flex: 1 1;
        order: 2;
        margin: 0;
      }
      &:after {
        margin: 0;
      }
    }
    .ant-form-item-explain {
      margin-top: 4px;
      .ant-form-item-explain-error {
        @include bodyMeta;
      }
    }
    .ant-btn.m-t-24 {
      @include respond-to($screen767) {
        margin-top: 20px;
      }
    }
  }
}
