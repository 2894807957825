@use '../utils/' as *;

/*container start here*/
.container {
  max-width: $container;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  @include respond-to($screen1170) {
    padding: 0 20px;
  }

  @include respond-to($screen767) {
    padding: 0 16px;
  }
}
.container-fluid {
  width: 100%;
  margin: auto;
  padding: 0 24px;
  @include respond-to($screen1170) {
    padding: 0 20px;
  }

  @include respond-to($screen767) {
    padding: 0 16px;
  }
}
/*container end here*/
